<template>
  <v-container>
    <v-row>
      <v-col
        cols="12"
        :md="showHelp ? 8 : 11"
        :sm="12"
        v-show="!showExpand"
        class="pr-0 pt-5 pb-0"
      >
        <GoBackBtn :routeToGo="routeToGo" :tabId="false" />
        <PageHeader :title="title" />
        <v-card class="mx-auto my-12 mt-2 mb-4 pt-0" max-width="1200" outlined>
          <v-container class="pt-0">
            <v-row justify="end">
              <v-col
                cols="1"
                align-self="center"
                align="center"
                class="pr-0 pl-0"
              >
                <v-tooltip left>
                  <template v-slot:activator="{ on, attrs }">
                    <v-icon
                      v-bind="attrs"
                      v-on="on"
                      class="primary--text"
                      @click.stop="toggleFiltersSelected"
                    >
                      {{ closeFiltersIcon }}
                    </v-icon>
                  </template>
                  <span>{{
                    !showFilters ? "Mostrar filtros" : "Ocultar filtros"
                  }}</span>
                </v-tooltip>
              </v-col>
            </v-row>
            <v-row>
              <v-col
                cols="12"
                align="left"
                class="pt-0 pb-0"
                @click="showFilters = true"
              >
                <FiltersSelected
                  :filters="filtersApplied"
                  v-if="!showFilters"
                />
              </v-col>
            </v-row>
            <v-expand-transition mode="out-in">
              <v-form
                v-show="showFilters"
                v-model="isFormValid"
                ref="filters-form"
                id="filters-form"
                @submit.prevent="applyFilters()"
              >
                <!-- Filtros comunes -->
                <v-row>
                  <!-- Codigo agente -->
                  <v-col cols="3" class="py-0">
                    <v-text-field
                      type="text"
                      outlined
                      dense
                      clearable
                      v-mask="'#########'"
                      label="Código de agente"
                      v-model="codigoAgenteCta"
                      @change="buscarAgente"
                      autocomplete="off"
                      :rules="rules.positiveNumber"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="4" class="py-0">
                    <v-text-field
                      type="text"
                      outlined
                      dense
                      clearable
                      disabled
                      label="Nombre de agente de cta."
                      v-model="nombreAgenteCta"
                      autocomplete="off"
                    ></v-text-field>
                  </v-col>
                  <!-- Boton buscar por nombre o dni -->
                  <v-col cols="1" class="py-0">
                    <v-tooltip right>
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn
                          v-on="on"
                          v-bind="attrs"
                          absolute
                          fab
                          small
                          color="primary"
                          :disabled="searchAgenteBtn"
                          @click="modalBusquedaAgecta = true"
                        >
                          <v-icon>{{ searchIcon }}</v-icon>
                        </v-btn>
                      </template>
                      <span>Buscar agente de cuenta</span>
                    </v-tooltip>
                  </v-col>
                  <!-- Tipo de comprobante -->
                  <v-col cols="4" class="py-0">
                    <v-autocomplete
                      v-model="tipoComprobanteSelected"
                      :items="tiposComprobantes"
                      label="Tipo de comprobante"
                      item-text="value"
                      item-value="id"
                      outlined
                      clearable
                      dense
                    >
                    </v-autocomplete>
                  </v-col>
                </v-row>
                <v-row>
                  <!-- Concepto -->
                  <v-col cols="4" class="py-0">
                    <v-autocomplete
                      v-model="conceptoSelected"
                      :items="conceptos"
                      label="Concepto"
                      item-text="value"
                      item-value="id"
                      outlined
                      clearable
                      dense
                    >
                    </v-autocomplete>
                  </v-col>
                  <!-- Importe desde -->
                  <v-col cols="3" class="py-0">
                    <currency-input
                      v-model="importeDesde"
                      label="Importe desde"
                      :options="currencyOptions"
                      :rules="
                        importeDesde && importeHasta
                          ? [rules.validDateRange(importeDesde, importeHasta)]
                          : []
                      "
                    ></currency-input>
                  </v-col>
                  <!-- Importe hasta -->
                  <v-col cols="3" class="py-0">
                    <currency-input
                      v-model="importeHasta"
                      label="Importe hasta"
                      :options="currencyOptions"
                      :rules="
                        importeDesde && importeHasta
                          ? [rules.validDateRange(importeDesde, importeHasta)]
                          : []
                      "
                    ></currency-input>
                  </v-col>
                  <!-- Período -->
                  <v-col cols="2" class="py-0">
                    <v-text-field
                      ref="periodo"
                      v-model="periodoSelected"
                      label="Período"
                      outlined
                      dense
                      clearable
                      autocomplete="not"
                      onkeypress="return (event.charCode >= 48 && event.charCode <= 57)"
                      :rules="rules.periodoYyyyMm"
                      hint="Formato AAAAMM"
                      persistent-hint
                    ></v-text-field>
                  </v-col>
                </v-row>
                <v-row justify="end">
                  <v-col cols="12" md="4" sm="6" class="pb-0 pt-5 text-right">
                    <v-tooltip bottom>
                      <template v-slot:activator="{ on, attrs }">
                        <v-icon
                          class="mr-2"
                          v-bind="attrs"
                          v-on="on"
                          size="28"
                          @click="resetForm"
                        >
                          {{ clearFiltersIcon }}
                        </v-icon>
                      </template>
                      <span>Limpiar filtros</span>
                    </v-tooltip>
                    <v-btn
                      color="primary"
                      :disabled="!isFormValid"
                      elevation="2"
                      small
                      type="submit"
                      form="filters-form"
                    >
                      Aplicar
                    </v-btn>
                  </v-col>
                </v-row>
              </v-form>
            </v-expand-transition>
          </v-container>
        </v-card>
        <v-card>
          <v-data-table
            :headers="headers"
            item-key="DevAdiExtraId"
            :items="items"
            :loading="loading"
            class="elevation-1"
            :search="search"
            loading-text="Cargando datos..."
          >
            <template v-slot:[`item.tieneIva`]="{ item }">
              <v-icon v-if="item.tieneIva" small color="primary">
                {{ checkIcon }}
              </v-icon>
            </template>
            <template v-slot:top>
              <v-toolbar flat>
                <v-row>
                  <v-col cols="7" :md="showHelp ? 6 : 7">
                    <v-text-field
                      v-model="search"
                      :append-icon="searchIcon"
                      label="Buscar"
                      single-line
                      hide-details
                    ></v-text-field>
                  </v-col>
                  <v-col cols="4" :md="showHelp ? 4 : 4" align="center">
                    <v-btn
                      color="primary"
                      class="no-upper-case"
                      v-if="canGoToProcesosActualizacion"
                      @click="goToProcesosActualizacionAdicionales()"
                    >
                      Procesos de actualización
                    </v-btn>
                  </v-col>
                  <v-col cols="1" :md="showHelp ? 2 : 1" align="end" v-if="canCreate">
                    <v-btn
                      color="primary"
                      @click="openModalEditAdicionalExtra()"
                      class="to-right"
                    >
                      Nuevo
                    </v-btn>
                  </v-col>
                </v-row>
              </v-toolbar>
            </template>
            <template v-slot:[`item.actions`]="{ item }">
              <v-tooltip left v-if="canEdit">
                <template v-slot:activator="{ on, attrs }">
                  <v-icon
                    v-bind="attrs"
                    size="20"
                    v-on="on"
                    @click="openModalEditAdicionalExtra(item)"
                  >
                    {{ editIcon }}
                  </v-icon>
                </template>
                <span>Editar adicional</span>
              </v-tooltip>
              <v-tooltip left v-if="canDelete">
                <template v-slot:activator="{ on, attrs }">
                  <v-icon
                    v-bind="attrs"
                    size="20"
                    v-on="on"
                    @click="deleteAdicional(item)"
                  >
                    {{ deleteIcon }}
                  </v-icon>
                </template>
                <span>Eliminar adicional</span>
              </v-tooltip>
            </template>
          </v-data-table>
        </v-card>
        <v-card-actions>
          <v-col cols="12" md="12" class="p-1 m-2">
            <v-tooltip top max-width="35%">
              <template v-slot:activator="{ attrs }">
                <v-btn
                  color="primary"
                  :disabled="items.length == 0"
                  class="to-right fontsize"
                  v-bind="attrs"
                  outlined
                  @click="exportExcel"
                >
                  Exportar detalle
                </v-btn>
              </template>
            </v-tooltip>
          </v-col>
        </v-card-actions>
      </v-col>
      <!-- Texto de ayuda principal -->
      <v-col
        cols="12"
        :md="!showHelp ? 1 : showExpand ? 12 : 4"
        class="text-right pt-8 px-0"
        v-if="showIcon"
      >
        <Ayuda
          :optionCode="optionCode"
          :pantallaTitle="title"
          @toggleExpand="showExpand = !showExpand"
          @toggleHelp="toggleHelp"
          @toggleIcon="toggleIcon()"
        />
      </v-col>
    </v-row>
    <DeleteDialog
      :titleProp="titleDelete"
      :openDelete.sync="showDeleteModal"
      @onDeleteItem="confirmDelete()"
    />
    <v-dialog
      v-model="modalBusquedaAgecta"
      v-if="modalBusquedaAgecta"
      @keydown.esc="toggleModalBusquedaAgecta"
      max-width="65%"
      persistent
    >
      <BuscarAgenteCuenta
        @toggleModalBusquedaAgecta="toggleModalBusquedaAgecta"
        @findAgenteCta="findAgenteCta"
      ></BuscarAgenteCuenta>
    </v-dialog>
    <v-dialog
      v-if="modalEditAdicionalExtra"
      v-model="modalEditAdicionalExtra"
      @keydown.esc="closeAndReload"
      max-width="65%"
      persistent
    >
      <EditAdicionalExtra
        :adicionalExtra="adicionalExtra"
        @closeAndReload="closeAndReload"
      ></EditAdicionalExtra>
    </v-dialog>
  </v-container>
</template>

<script>
import PageHeader from "@/components/ui/PageHeader";
import { mapActions } from "vuex";
import enums from "@/utils/enums/index.js";
import rules from "@/utils/helpers/rules";
import { mask } from "vue-the-mask";
import Ayuda from "@/components/shared/Ayuda.vue";
import BuscarAgenteCuenta from "@/components/modules/cuotas/agenteCuenta/BuscarAgenteCuenta.vue";
import FiltersSelected from "@/components/shared/FiltersSelected";
import EditAdicionalExtra from "./EditAdicionalExtra.vue";
import DeleteDialog from "@/components/shared/DeleteDialog";
import helpersExcel from "@/utils/helpers/importExportExcel";
import GoBackBtn from "@/components/shared/GoBackBtn";
import CurrencyInput from "@/components/shared/CurrencyInput.vue";

export default {
  name: "AdicionalesExtra",
  components: {
    PageHeader,
    Ayuda,
    BuscarAgenteCuenta,
    FiltersSelected,
    EditAdicionalExtra,
    CurrencyInput,
    DeleteDialog,
    GoBackBtn
  },
  directives: { mask },
  data() {
    return {
      title: enums.titles.ADICIONALES_EXTRA,
      titleDelete: enums.titles.DELETE_ADICIONAL_EXTRA,
      calendarIcon: enums.icons.CALENDAR,
      excelIcon: enums.icons.EXPORT_EXCEL,
      deleteIcon: enums.icons.DELETE,
      searchIcon: enums.icons.SEARCH,
      closeFiltersIcon: enums.icons.CLOSE_FILTERS,
      clearFiltersIcon: enums.icons.CLEAR_FILTERS,
      routeToGo: "ImportesADevengar",
      editIcon: enums.icons.EDIT,
      optionCode: enums.webSiteOptions.ADICIONALES_EXTRA,
      checkIcon: enums.icons.CHECK_OUTLINE,
      showDeleteModal: false,
      rules: rules,
      showExpand: false,
      showHelp: false,
      text: "BuscarDeuda",
      showFilters: true,
      filtersApplied: [],
      isFormValid: false,
      loading: false,
      search: "",
      canCreate: false,
      canDelete: false,
      canEdit: false,
      canBuscarDeuda: false,
      canProcesarCobros: false,
      codigoAgenteCta: null,
      tiposComprobantes: [],
      tipoComprobanteSelected: null,
      conceptos: [],
      conceptoSelected: null,
      importeDesde: null,
      importeHasta: null,
      periodoSelected: null,
      items: [],
      showIcon: true,
      searchAgenteBtn: false,
      modalBusquedaAgecta: false,
      modalEditAdicionalExtra: false,
      nombreAgenteCta: null,
      adicionalExtra: null,
      adicionalIDToDelete: null,
      currencyOptions: {
        locale: "nl-NL",
        currency: "USD",
        currencyDisplay: "narrowSymbol",
        valueRange: {
          min: 1,
          max: 999999999999999.99
        },
        precision: undefined,
        hideCurrencySymbolOnFocus: true,
        hideGroupingSeparatorOnFocus: true,
        hideNegligibleDecimalDigitsOnFocus: true,
        autoDecimalDigits: false,
        exportValueAsInteger: false,
        autoSign: true,
        useGrouping: true
      },
      headers: [
        {
          text: "Agente de cuenta",
          value: "agectaNom",
          align: "start",
          sortable: false
        },
        {
          text: "Comprobante",
          value: "comprobante",
          sortable: false,
          align: "start"
        },
        {
          text: "Concepto",
          value: "concepto",
          align: "start",
          sortable: false
        },
        {
          text: "Sucursal",
          value: "sucursal",
          align: "center",
          sortable: false
        },
        {
          text: "Importe",
          value: "importeString",
          sortable: false,
          align: "end"
        },
        {
          text: "Tiene IVA",
          value: "tieneIva",
          align: "center",
          sortable: false
        },
        {
          text: "Período desde",
          value: "periodoDesde",
          align: "end",
          sortable: false
        },
        {
          text: "Período hasta",
          value: "periodoHasta",
          align: "end",
          sortable: false
        },
        { text: "Acciones", value: "actions", sortable: false, align: "end" }
      ],
      canGoToProcesosActualizacion: false
    };
  },
  created() {
    this.$store.dispatch("user/changeAppLoading", {
      status: false,
      text: "",
      opaque: false
    });
    this.setSelects();
  },
  mounted() {
    this.allowedActions = JSON.parse(localStorage.getItem("allowedActions"));
    this.setPermisos();
  },
  methods: {
    ...mapActions({
      getAgenteCuentaByCodigo: "afiliaciones/getAgenteCuentaByCodigo",
      getTiposComprobantesDeudaIngreso:
        "afiliaciones/getTiposComprobantesDeudaIngreso",
      getConceptos: "devengamientos/getConceptos",
      getAdicionalesExtra: "devengamientos/getAdicionalesExtra",
      deleteAdicionalExtra: "devengamientos/deleteAdicionalExtra",
      getAdicionalExtraByID: "devengamientos/getAdicionalExtraByID",
      setAlert: "user/setAlert"
    }),
    setPermisos() {
      this.allowedActions?.forEach(x => {
        switch (x.optionCode) {
          case enums.modules.adminSistema.permissions.NUEVO_ADICIONAL_EXTRA:
            this.canCreate = true;
            break;
          case enums.modules.adminSistema.permissions.EDITAR_ADICIONAL_EXTRA:
            this.canEdit = true;
            break;
          case enums.modules.adminSistema.permissions.ELIMINAR_ADICIONAL_EXTRA:
            this.canDelete = true;
            break;
          case enums.modules.adminSistema.permissions.ACTUALIZACION_ADICIONALES_EXTRA:
            this.canGoToProcesosActualizacion = true;
            break;
          // Para futuros procesos
          // case enums.modules.adminSistema.permissions.BUSCAR_DEUDA:
          //   this.canBuscarDeuda = true;
          //   break;
          // case enums.modules.adminSistema.permissions.PROCESAR_COBROS:
          //   this.canProcesarCobros = true;
          //   break;
          default:
            break;
        }
      });
    },
    async setSelects() {
      const tiposComprobantesReponse = await this.getTiposComprobantesDeudaIngreso();
      this.tiposComprobantes = tiposComprobantesReponse;
      const conceptosResponse = await this.getConceptos();
      this.conceptos = conceptosResponse;
    },
    deleteAdicional(item) {
      this.showDeleteModal = true;
      this.adicionalIDToDelete = item.devAdiExtraId;
    },
    async confirmDelete() {
      const response = await this.deleteAdicionalExtra(
        this.adicionalIDToDelete
      );
      if (response.status === 200) {
        this.showDeleteModal = false;
        this.setAlert({ type: "success", message: "Eliminado con éxito." });
        this.applyFilters();
      }
    },
    exportExcel() {
      let result = [];
      this.items.forEach(x =>
        result.push({
          ["Agente de cuenta"]: x.agectaNom,
          ["Comprobante"]: x.comprobante,
          ["Concepto"]: x.concepto,
          ["Importe total"]: x.importe,
          ["Periodo desde"]: x.periodoDesde,
          ["Periodo Hasta"]: x.periodoHasta,
          ["IVA incluido"]: x.tieneIva ? "SI" : "NO"
        })
      );
      let formato = {
        filas: result,
        hoja: "Resultado"
      };
      helpersExcel.excelExport(formato, "Detalle adicionales");
    },
    formatPrice(importe) {
      const formatPrice = new Intl.NumberFormat("es-AR");
      const importeFormatted = formatPrice.format(importe);
      return importeFormatted;
    },
    // metodos de filtros
    async applyFilters() {
      this.loading = true;
      this.customizeFiltersApplied();
      const filters = {
        AgectaId: this.codigoAgenteCta,
        TCompId: this.tipoComprobanteSelected,
        ConceptoId: this.conceptoSelected,
        ImporteDesde: this.importeDesde,
        ImporteHasta: this.importeHasta,
        VigenteAl: this.periodoSelected
      };
      try {
        const response = await this.getAdicionalesExtra(filters);
        response.forEach(item => {
          item.importe = this.formatPrice(item.importe);
        });
        this.items = response;
        this.loading = false;
      } catch {
        this.loading = false;
      }
    },
    customizeFiltersApplied() {
      // lo quito y agrego manualmente ya que el componente de filtros lo toma como undefined
      this.filtersApplied = [];
      if (this.nombreAgenteCta) {
        this.filtersApplied.splice(0, 1, {
          key: "nombreAgenteCta",
          label: "Agente de cuenta",
          model: this.nombreAgenteCta
        });
      }
      if (this.tipoComprobanteSelected) {
        this.filtersApplied.splice(1, 1, {
          key: "tipoComprobanteSelected",
          label: "Tipo de comprobante",
          model: this.tipoComprobanteSelected
        });
      }
      if (this.conceptoSelected) {
        this.filtersApplied.splice(2, 1, {
          key: "conceptoSelected",
          label: "Concepto",
          model: this.conceptoSelected
        });
      }
      if (this.importeDesde) {
        this.filtersApplied.splice(3, 1, {
          key: "importeDesde",
          label: "Importe desde",
          model: this.nroCompimporteDesderobante
        });
      }
      if (this.importeHasta) {
        this.filtersApplied.splice(4, 1, {
          key: "importeHasta",
          label: "Importe hasta",
          model: this.importeHasta
        });
      }
      if (this.periodoSelected) {
        this.filtersApplied.splice(4, 1, {
          key: "periodoSelected",
          label: "Periodo",
          model: this.periodoSelected
        });
      }
    },
    resetForm() {
      this.codigoAgenteCta = null;
      this.nombreAgenteCta = null;
      this.tipoComprobanteSelected = null;
      this.conceptoSelected = null;
      this.importeDesde = null;
      this.importeHasta = null;
      this.periodoSelected = null;
    },
    // metodos busqueda de agente de cuenta
    findAgenteCta(agenteSelected) {
      this.codigoAgenteCta = agenteSelected.agenteId.toString();
      this.nombreAgenteCta = agenteSelected.nombre;
    },
    async searchAgenteCta(codigoAgte) {
      const response = await this.getAgenteCuentaByCodigo({
        codigo: codigoAgte,
        nombre: "",
        dni: "",
        cuit: ""
      });
      const agenteResp = response[0];
      if (agenteResp) {
        return agenteResp;
      }
    },
    async buscarAgente() {
      if (this.codigoAgenteCta != null) {
        const search = await this.searchAgenteCta(this.codigoAgenteCta);
        this.findAgenteCta(search);
      } else {
        this.nombreAgenteCta = null;
      }
    },
    // toggles
    toggleHelp() {
      this.showHelp = !this.showHelp;
      this.showHelp ? [] : (this.showExpand = false);
    },
    toggleModalBusquedaAgecta() {
      this.modalBusquedaAgecta = !this.modalBusquedaAgecta;
    },
    toggleFiltersSelected() {
      this.customizeFiltersApplied();
      this.showFilters = !this.showFilters;
    },
    closeAndReload() {
      this.modalEditAdicionalExtra = false;
      if (
        this.codigoAgenteCta != null ||
        this.nroGrupo != null ||
        this.conceptoSelected != null ||
        this.fecha != null ||
        this.userSelected != null ||
        this.periodo != null
      )
        this.applyFilters();
    },
    openModalEditAdicionalExtra(item) {
      this.modalEditAdicionalExtra = true;
      this.adicionalExtra = item;
    },
    goToProcesosActualizacionAdicionales() {
      this.$router.push({
        name: "ProcesoActualizacionAdicionales"
      });
      this.$store.dispatch("user/changeAppLoading", {
        status: true,
        text: "",
        opaque: true
      });
    }
  }
};
</script>

<style lang="scss" scoped>
::v-deep input::-webkit-outer-spin-button,
::v-deep input::-webkit-inner-spin-button {
  -webkit-webkit-appearance: none;
  margin: 0;
}
::v-deep input[type="number"] {
  -webkit-moz-appearance: textfield;
}
.fontsize {
  font-size: 12px;
}
.no-upper-case {
  text-transform: none;
}
</style>
