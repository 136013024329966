<template>
  <v-card>
    <v-container>
      <v-card-title>
        <span class="pl-1 primary--text">{{ formEditTitle }}</span>
      </v-card-title>
      <v-card-text>
        <v-form
          v-model="isFormValid"
          ref="form"
          form="form"
          id="form"
          @submit.prevent="saveEdit()"
        >
          <v-row>
            <!-- Codigo agente -->
            <v-col cols="4" class="py-0">
              <v-text-field
                type="text"
                outlined
                dense
                clearable
                v-mask="'#########'"
                class="theme--light.v-input input"
                label="Agente de cuenta"
                :rules="rules.required"
                v-model="codigoAgenteCta"
                @change="
                  codigoAgenteCta == null || codigoAgenteCta == ''
                    ? $refs['nombreAgecta'].reset()
                    : null
                "
                autocomplete="off"
              ></v-text-field>
            </v-col>
            <v-col cols="4" class="py-0">
              <v-text-field
                type="text"
                outlined
                dense
                clearable
                disabled
                ref="nombreAgecta"
                label="Nombre de agente de cta."
                v-model="nombreAgenteCta"
                :rules="rules.required"
                autocomplete="off"
              ></v-text-field>
            </v-col>
            <!-- Boton buscar -->
            <v-col cols="2" sm="6" md="2" class="py-0">
              <v-btn
                color="primary"
                :disabled="
                  codigoAgenteCta == null ||
                    codigoAgenteCta == undefined ||
                    codigoAgenteCta == ''
                "
                @click="findAgenteCta()"
              >
                Buscar
              </v-btn>
            </v-col>
            <!-- Boton buscar por nombre o dni -->
            <v-col cols="2" class="py-0 pl-8">
              <v-tooltip right>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    v-on="on"
                    v-bind="attrs"
                    absolute
                    fab
                    small
                    color="primary"
                    :disabled="
                      codigoAgenteCta != null &&
                        codigoAgenteCta != undefined &&
                        codigoAgenteCta != ''
                    "
                    @click="toggleModalBusquedaAgecta()"
                  >
                    <v-icon>{{ searchIcon }}</v-icon>
                  </v-btn>
                </template>
                <span>Buscar por otro</span>
              </v-tooltip>
            </v-col>
          </v-row>
          <v-row>
            <!-- Tipo de comprobante -->
            <v-col cols="4" class="py-0">
              <v-autocomplete
                v-model="tipoComprobanteSelected"
                :items="tiposComprobantes"
                return-object
                label="Tipo de comprobante"
                item-text="value"
                item-value="id"
                outlined
                 @change="
                  tipoComprobanteSelected !== null &&
                  tipoComprobanteSelected !== undefined
                    ? deshabilitarSucursal(tipoComprobanteSelected)
                    : (disabledSucursal = false)
                "
                clearable
                dense
                :rules="rules.required"
              >
              </v-autocomplete>
            </v-col>
            <v-col cols="4" md="3" class="py-0">
              <v-text-field
                v-model="sucursal"
                label="Sucursal"
                outlined
                dense
                :disabled="disabledSucursal"
                autocomplete="off"
                :rules="
                  sucursal === undefined
                    ? rules.required
                    : rules.positiveNumberHigher0
                "
                v-mask="'####'"
              ></v-text-field>
            </v-col>
            <!-- Formas de pago -->
            <v-col cols="4" class="py-0">
              <v-autocomplete
                v-model="formaPagoSelected"
                :items="formasPago"
                label="Formas de pago"
                item-text="value"
                item-value="id"
                outlined
                clearable
                dense
                return-object
                @change="
                  formaPagoSelected == null || formaPagoSelected == ''
                    ? $refs['entFinSelected'].reset()
                    : null
                "
                :rules="rules.required"
              >
              </v-autocomplete>
            </v-col>
            <!-- Entidad financiera -->
          </v-row>
          <v-row>
            <!-- Concepto -->
            <v-col cols="6" class="py-0">
              <v-autocomplete
                v-model="conceptoSelected"
                :items="conceptos"
                label="Concepto"
                item-text="value"
                item-value="id"
                outlined
                clearable
                dense
                :rules="rules.required"
              >
              </v-autocomplete>
            </v-col>
            <v-col cols="3" class="py-0">
              <!-- Periodo desde -->
              <v-text-field
                ref="periodoDesde"
                v-model="periodoDesde"
                label="Período desde"
                outlined
                dense
                v-mask="'######'"
                autocomplete="not"
                :rules="
                  rules.required.concat(
                    rules.periodoYyyyMm,
                    rules.validDateRange(periodoDesde, periodoHasta)
                  )
                "
                hint="Formato AAAAMM"
                persistent-hint
              >
              </v-text-field>
            </v-col>
            <!-- Periodo hasta -->
            <v-col cols="3" class="py-0">
              <v-text-field
                ref="periodoHasta"
                v-model="periodoHasta"
                label="Período hasta"
                outlined
                dense
                v-mask="'######'"
                autocomplete="not"
                :rules="
                  rules.required.concat(
                    rules.periodoYyyyMm,
                    rules.validDateRange(periodoDesde, periodoHasta)
                  )
                "
                hint="Formato AAAAMM"
                persistent-hint
              >
              </v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="4" class="py-0">
              <v-autocomplete
                v-model="entFinSelected"
                :items="entFinancieras"
                :disabled="
                  formaPagoSelected != null ? !formaPagoSelected.bool : true
                "
                label="Entidad financiera"
                ref="entFinSelected"
                item-text="value"
                item-value="id"
                outlined
                clearable
                dense
              >
              </v-autocomplete>
            </v-col>
            <!-- Importe -->
            <v-col cols="4" class="py-0">
              <currency-input
                label="Importe"
                v-model="importe"
                class="theme--light.v-input input"
                dense="false"
                outlined="false"
                :options="currencyOptions"
                :rules="rules.required"
              >
              </currency-input>
            </v-col>
            <v-col cols="3" class="py-0">
              <v-switch
                v-model="tieneIva"
                id="tieneIva"
                label="Con IVA incluido"
                class="mt-1"
              ></v-switch>
            </v-col>
            <!-- Sucursal -->
          </v-row>
        </v-form>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn outlined @click="closeModal"> Cancelar </v-btn>
        <v-btn
          type="submit"
          :disabled="!isFormValid"
          form="form"
          color="primary"
        >
          Guardar
        </v-btn>
      </v-card-actions>
      <v-dialog
        v-model="modalBusquedaAgecta"
        v-if="modalBusquedaAgecta"
        @keydown.esc="toggleModalBusquedaAgecta"
        max-width="65%"
        persistent
      >
        <BuscarAgenteCuenta
          @toggleModalBusquedaAgecta="toggleModalBusquedaAgecta"
          @findAgenteCta="findAgenteCta"
        />
      </v-dialog>
    </v-container>
  </v-card>
</template>

<script>
import { mapActions } from "vuex";
import rules from "@/utils/helpers/rules";
import enums from "@/utils/enums/index.js";
import BuscarAgenteCuenta from "@/components/modules/cuotas/agenteCuenta/BuscarAgenteCuenta.vue";
import { mask } from "vue-the-mask";
import CurrencyInput from "@/components/shared/CurrencyInput.vue";

export default {
  name: "EditAdicionalExtra",
  components: { BuscarAgenteCuenta, CurrencyInput },
  directives: { mask },
  data() {
    return {
      rules: rules,
      formEditTitle: "Editar adicional extra",
      searchIcon: enums.icons.SEARCH,
      codigoAgenteCta: null,
      nombreAgenteCta: null,
      tipoComprobanteSelected: null,
      conceptoSelected: null,
      formaPagoSelected: null,
      entFinSelected: null,
      importe: null,
      tieneIva: false,
      sucursal: null,
      periodoDesde: null,
      periodoHasta: null,
      isFormValid: false,
      modalBusquedaAgecta: false,
      tiposComprobantes: [],
      conceptos: [],
      formasPago: [],
      entFinancieras: [],
      currencyOptions: {
        locale: "nl-NL",
        currency: "USD",
        currencyDisplay: "narrowSymbol",
        valueRange: {
          min: 1,
          max: 999999999999999.99
        },
        precision: undefined,
        hideCurrencySymbolOnFocus: true,
        hideGroupingSeparatorOnFocus: true,
        hideNegligibleDecimalDigitsOnFocus: true,
        autoDecimalDigits: false,
        exportValueAsInteger: false,
        autoSign: true,
        useGrouping: false
      },
      disabledSucursal: false,
    };
  },
  props: {
    adicionalExtra: {
      type: Object,
      required: false
    }
  },
  async created() {
    await this.setSelects();
    if (this.adicionalExtra != null) {
      this.setAdicional();
    } else {
      this.newAdicional();
    }
  },
  methods: {
    ...mapActions({
      getAgenteCuentaByCodigo: "afiliaciones/getAgenteCuentaByCodigo",
      getConceptos: "devengamientos/getConceptos",
      getFormasDePagoPideEntFin: "devengamientos/getFormasDePagoPideEntFin",
      getEntidadesFinancieras: "devengamientos/getEntidadesFinancieras",
      getTiposComprobantesDeudaIngreso:
        "afiliaciones/getTiposComprobantesDeudaIngreso",
      saveAdicionalExtra: "devengamientos/saveAdicionalExtra",
      setAlert: "user/setAlert"
    }),
    setAdicional() {
      this.codigoAgenteCta = this.adicionalExtra.agectaId;
      this.nombreAgenteCta = this.adicionalExtra.agectaNom;
      this.tipoComprobanteSelected = this.adicionalExtra.tCompId;
      this.conceptoSelected = this.adicionalExtra.concId;
      this.entFinSelected = this.adicionalExtra.entfinId;
      this.formaPagoSelected = this.adicionalExtra.formaPago;
      this.importe = this.adicionalExtra.importeString;
      this.periodoDesde = this.adicionalExtra.periodoDesde;
      this.periodoHasta = this.adicionalExtra.periodoHasta;
      this.tieneIva = this.adicionalExtra?.tieneIva ? true : false;
      let tipoComprobante = this.tiposComprobantes.find(
        x => x.id == this.tipoComprobanteSelected
      );
      this.deshabilitarSucursal(tipoComprobante);
      this.sucursal = this.adicionalExtra.sucursal;
    },
     deshabilitarSucursal(obj) {
      if (obj.bool === true) {
        this.disabledSucursal = true;
        this.sucursal = 9999;
      } else {
        this.disabledSucursal = false;
        if(this.adicionalExtra != null){this.sucursal = this.adicionalExtra.sucursal}
      }
    },
    toggleModalBusquedaAgecta() {
      this.modalBusquedaAgecta = !this.modalBusquedaAgecta;
    },
    newAdicional() {
      this.formEditTitle = "Nuevo adicional extra";
    },
    async findAgenteCta(agenteSelected) {
      if (agenteSelected == undefined || agenteSelected == {}) {
        const response = await this.getAgenteCuentaByCodigo({
          codigo: this.codigoAgenteCta,
          nombre: "",
          dni: "",
          cuit: ""
        });
        this.agente = response[0];
        if (this.agente != null) {
          this.codigoAgenteCta = this.agente.agenteId;
          this.nombreAgenteCta = this.agente.nombre;
          this.setAlert({
            type: "success",
            message: `El agente ${this.agente.nombre} ha sido seleccionado.`
          });
        }
      } else {
        this.agente = agenteSelected;
        this.codigoAgenteCta = agenteSelected.agenteId;
        this.nombreAgenteCta = agenteSelected.nombre;
        this.setAlert({
          type: "success",
          message: `El agente ${agenteSelected.nombre} ha sido seleccionado.`
        });
      }
    },
    async saveEdit() {
      const data = {
        DevAdiExtraId:
          this.adicionalExtra != null
            ? this.adicionalExtra.devAdiExtraId
            : null,
        AgectaId: this.codigoAgenteCta,
        TcompId: typeof this.tipoComprobanteSelected === "object"
            ? this.tipoComprobanteSelected.id
            : this.tipoComprobanteSelected,
        ConceptoId: this.conceptoSelected,
        FPagoId: this.formaPagoSelected.id,
        EntFinId:
          this.formaPagoSelected != null && this.entFinSelected != null
            ? this.entFinSelected
            : 0,
        Importe: this.importe,
        PeriodoDesde: this.periodoDesde,
        PeriodoHasta: this.periodoHasta,
        TieneIva: this.tieneIva,
        Sucursal: this.sucursal
      };
      const res = await this.saveAdicionalExtra(data);
      if (res.status === 200) {
        this.setAlert({ type: "success", message: "Guardado con éxito" });
        this.closeModal();
      }
    },
    async setSelects() {
      const tiposComprobantesReponse = await this.getTiposComprobantesDeudaIngreso();
      this.tiposComprobantes = tiposComprobantesReponse;
      const conceptosResponse = await this.getConceptos();
      this.conceptos = conceptosResponse;
      const formasPagoResponse = await this.getFormasDePagoPideEntFin();
      this.formasPago = formasPagoResponse;
      const entFinancierasResponse = await this.getEntidadesFinancieras();
      this.entFinancieras = entFinancierasResponse;
    },
    closeModal() {
      this.$emit("closeAndReload");
    }
  }
};
</script>

<style lang="scss" scoped>
::v-deep .v-input__slot {
  cursor: context-menu !important;
}
::v-deep label {
  margin-bottom: 0;
}
</style>
