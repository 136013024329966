var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',[_c('v-row',[_c('v-col',{directives:[{name:"show",rawName:"v-show",value:(!_vm.showExpand),expression:"!showExpand"}],staticClass:"pr-0 pt-5 pb-0",attrs:{"cols":"12","md":_vm.showHelp ? 8 : 11,"sm":12}},[_c('GoBackBtn',{attrs:{"routeToGo":_vm.routeToGo,"tabId":false}}),_c('PageHeader',{attrs:{"title":_vm.title}}),_c('v-card',{staticClass:"mx-auto my-12 mt-2 mb-4 pt-0",attrs:{"max-width":"1200","outlined":""}},[_c('v-container',{staticClass:"pt-0"},[_c('v-row',{attrs:{"justify":"end"}},[_c('v-col',{staticClass:"pr-0 pl-0",attrs:{"cols":"1","align-self":"center","align":"center"}},[_c('v-tooltip',{attrs:{"left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({staticClass:"primary--text",on:{"click":function($event){$event.stopPropagation();return _vm.toggleFiltersSelected($event)}}},'v-icon',attrs,false),on),[_vm._v(" "+_vm._s(_vm.closeFiltersIcon)+" ")])]}}])},[_c('span',[_vm._v(_vm._s(!_vm.showFilters ? "Mostrar filtros" : "Ocultar filtros"))])])],1)],1),_c('v-row',[_c('v-col',{staticClass:"pt-0 pb-0",attrs:{"cols":"12","align":"left"},on:{"click":function($event){_vm.showFilters = true}}},[(!_vm.showFilters)?_c('FiltersSelected',{attrs:{"filters":_vm.filtersApplied}}):_vm._e()],1)],1),_c('v-expand-transition',{attrs:{"mode":"out-in"}},[_c('v-form',{directives:[{name:"show",rawName:"v-show",value:(_vm.showFilters),expression:"showFilters"}],ref:"filters-form",attrs:{"id":"filters-form"},on:{"submit":function($event){$event.preventDefault();return _vm.applyFilters()}},model:{value:(_vm.isFormValid),callback:function ($$v) {_vm.isFormValid=$$v},expression:"isFormValid"}},[_c('v-row',[_c('v-col',{staticClass:"py-0",attrs:{"cols":"3"}},[_c('v-text-field',{directives:[{name:"mask",rawName:"v-mask",value:('#########'),expression:"'#########'"}],attrs:{"type":"text","outlined":"","dense":"","clearable":"","label":"Código de agente","autocomplete":"off","rules":_vm.rules.positiveNumber},on:{"change":_vm.buscarAgente},model:{value:(_vm.codigoAgenteCta),callback:function ($$v) {_vm.codigoAgenteCta=$$v},expression:"codigoAgenteCta"}})],1),_c('v-col',{staticClass:"py-0",attrs:{"cols":"4"}},[_c('v-text-field',{attrs:{"type":"text","outlined":"","dense":"","clearable":"","disabled":"","label":"Nombre de agente de cta.","autocomplete":"off"},model:{value:(_vm.nombreAgenteCta),callback:function ($$v) {_vm.nombreAgenteCta=$$v},expression:"nombreAgenteCta"}})],1),_c('v-col',{staticClass:"py-0",attrs:{"cols":"1"}},[_c('v-tooltip',{attrs:{"right":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"absolute":"","fab":"","small":"","color":"primary","disabled":_vm.searchAgenteBtn},on:{"click":function($event){_vm.modalBusquedaAgecta = true}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v(_vm._s(_vm.searchIcon))])],1)]}}])},[_c('span',[_vm._v("Buscar agente de cuenta")])])],1),_c('v-col',{staticClass:"py-0",attrs:{"cols":"4"}},[_c('v-autocomplete',{attrs:{"items":_vm.tiposComprobantes,"label":"Tipo de comprobante","item-text":"value","item-value":"id","outlined":"","clearable":"","dense":""},model:{value:(_vm.tipoComprobanteSelected),callback:function ($$v) {_vm.tipoComprobanteSelected=$$v},expression:"tipoComprobanteSelected"}})],1)],1),_c('v-row',[_c('v-col',{staticClass:"py-0",attrs:{"cols":"4"}},[_c('v-autocomplete',{attrs:{"items":_vm.conceptos,"label":"Concepto","item-text":"value","item-value":"id","outlined":"","clearable":"","dense":""},model:{value:(_vm.conceptoSelected),callback:function ($$v) {_vm.conceptoSelected=$$v},expression:"conceptoSelected"}})],1),_c('v-col',{staticClass:"py-0",attrs:{"cols":"3"}},[_c('currency-input',{attrs:{"label":"Importe desde","options":_vm.currencyOptions,"rules":_vm.importeDesde && _vm.importeHasta
                        ? [_vm.rules.validDateRange(_vm.importeDesde, _vm.importeHasta)]
                        : []},model:{value:(_vm.importeDesde),callback:function ($$v) {_vm.importeDesde=$$v},expression:"importeDesde"}})],1),_c('v-col',{staticClass:"py-0",attrs:{"cols":"3"}},[_c('currency-input',{attrs:{"label":"Importe hasta","options":_vm.currencyOptions,"rules":_vm.importeDesde && _vm.importeHasta
                        ? [_vm.rules.validDateRange(_vm.importeDesde, _vm.importeHasta)]
                        : []},model:{value:(_vm.importeHasta),callback:function ($$v) {_vm.importeHasta=$$v},expression:"importeHasta"}})],1),_c('v-col',{staticClass:"py-0",attrs:{"cols":"2"}},[_c('v-text-field',{ref:"periodo",attrs:{"label":"Período","outlined":"","dense":"","clearable":"","autocomplete":"not","onkeypress":"return (event.charCode >= 48 && event.charCode <= 57)","rules":_vm.rules.periodoYyyyMm,"hint":"Formato AAAAMM","persistent-hint":""},model:{value:(_vm.periodoSelected),callback:function ($$v) {_vm.periodoSelected=$$v},expression:"periodoSelected"}})],1)],1),_c('v-row',{attrs:{"justify":"end"}},[_c('v-col',{staticClass:"pb-0 pt-5 text-right",attrs:{"cols":"12","md":"4","sm":"6"}},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                        var on = ref.on;
                        var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({staticClass:"mr-2",attrs:{"size":"28"},on:{"click":_vm.resetForm}},'v-icon',attrs,false),on),[_vm._v(" "+_vm._s(_vm.clearFiltersIcon)+" ")])]}}])},[_c('span',[_vm._v("Limpiar filtros")])]),_c('v-btn',{attrs:{"color":"primary","disabled":!_vm.isFormValid,"elevation":"2","small":"","type":"submit","form":"filters-form"}},[_vm._v(" Aplicar ")])],1)],1)],1)],1)],1)],1),_c('v-card',[_c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.headers,"item-key":"DevAdiExtraId","items":_vm.items,"loading":_vm.loading,"search":_vm.search,"loading-text":"Cargando datos..."},scopedSlots:_vm._u([{key:"item.tieneIva",fn:function(ref){
                        var item = ref.item;
return [(item.tieneIva)?_c('v-icon',{attrs:{"small":"","color":"primary"}},[_vm._v(" "+_vm._s(_vm.checkIcon)+" ")]):_vm._e()]}},{key:"top",fn:function(){return [_c('v-toolbar',{attrs:{"flat":""}},[_c('v-row',[_c('v-col',{attrs:{"cols":"7","md":_vm.showHelp ? 6 : 7}},[_c('v-text-field',{attrs:{"append-icon":_vm.searchIcon,"label":"Buscar","single-line":"","hide-details":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1),_c('v-col',{attrs:{"cols":"4","md":_vm.showHelp ? 4 : 4,"align":"center"}},[(_vm.canGoToProcesosActualizacion)?_c('v-btn',{staticClass:"no-upper-case",attrs:{"color":"primary"},on:{"click":function($event){return _vm.goToProcesosActualizacionAdicionales()}}},[_vm._v(" Procesos de actualización ")]):_vm._e()],1),(_vm.canCreate)?_c('v-col',{attrs:{"cols":"1","md":_vm.showHelp ? 2 : 1,"align":"end"}},[_c('v-btn',{staticClass:"to-right",attrs:{"color":"primary"},on:{"click":function($event){return _vm.openModalEditAdicionalExtra()}}},[_vm._v(" Nuevo ")])],1):_vm._e()],1)],1)]},proxy:true},{key:"item.actions",fn:function(ref){
                        var item = ref.item;
return [(_vm.canEdit)?_c('v-tooltip',{attrs:{"left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                        var on = ref.on;
                        var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({attrs:{"size":"20"},on:{"click":function($event){return _vm.openModalEditAdicionalExtra(item)}}},'v-icon',attrs,false),on),[_vm._v(" "+_vm._s(_vm.editIcon)+" ")])]}}],null,true)},[_c('span',[_vm._v("Editar adicional")])]):_vm._e(),(_vm.canDelete)?_c('v-tooltip',{attrs:{"left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                        var on = ref.on;
                        var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({attrs:{"size":"20"},on:{"click":function($event){return _vm.deleteAdicional(item)}}},'v-icon',attrs,false),on),[_vm._v(" "+_vm._s(_vm.deleteIcon)+" ")])]}}],null,true)},[_c('span',[_vm._v("Eliminar adicional")])]):_vm._e()]}}],null,true)})],1),_c('v-card-actions',[_c('v-col',{staticClass:"p-1 m-2",attrs:{"cols":"12","md":"12"}},[_c('v-tooltip',{attrs:{"top":"","max-width":"35%"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                        var attrs = ref.attrs;
return [_c('v-btn',_vm._b({staticClass:"to-right fontsize",attrs:{"color":"primary","disabled":_vm.items.length == 0,"outlined":""},on:{"click":_vm.exportExcel}},'v-btn',attrs,false),[_vm._v(" Exportar detalle ")])]}}])})],1)],1)],1),(_vm.showIcon)?_c('v-col',{staticClass:"text-right pt-8 px-0",attrs:{"cols":"12","md":!_vm.showHelp ? 1 : _vm.showExpand ? 12 : 4}},[_c('Ayuda',{attrs:{"optionCode":_vm.optionCode,"pantallaTitle":_vm.title},on:{"toggleExpand":function($event){_vm.showExpand = !_vm.showExpand},"toggleHelp":_vm.toggleHelp,"toggleIcon":function($event){return _vm.toggleIcon()}}})],1):_vm._e()],1),_c('DeleteDialog',{attrs:{"titleProp":_vm.titleDelete,"openDelete":_vm.showDeleteModal},on:{"update:openDelete":function($event){_vm.showDeleteModal=$event},"update:open-delete":function($event){_vm.showDeleteModal=$event},"onDeleteItem":function($event){return _vm.confirmDelete()}}}),(_vm.modalBusquedaAgecta)?_c('v-dialog',{attrs:{"max-width":"65%","persistent":""},on:{"keydown":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"esc",27,$event.key,["Esc","Escape"])){ return null; }return _vm.toggleModalBusquedaAgecta($event)}},model:{value:(_vm.modalBusquedaAgecta),callback:function ($$v) {_vm.modalBusquedaAgecta=$$v},expression:"modalBusquedaAgecta"}},[_c('BuscarAgenteCuenta',{on:{"toggleModalBusquedaAgecta":_vm.toggleModalBusquedaAgecta,"findAgenteCta":_vm.findAgenteCta}})],1):_vm._e(),(_vm.modalEditAdicionalExtra)?_c('v-dialog',{attrs:{"max-width":"65%","persistent":""},on:{"keydown":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"esc",27,$event.key,["Esc","Escape"])){ return null; }return _vm.closeAndReload($event)}},model:{value:(_vm.modalEditAdicionalExtra),callback:function ($$v) {_vm.modalEditAdicionalExtra=$$v},expression:"modalEditAdicionalExtra"}},[_c('EditAdicionalExtra',{attrs:{"adicionalExtra":_vm.adicionalExtra},on:{"closeAndReload":_vm.closeAndReload}})],1):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }