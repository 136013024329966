var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',[_c('v-container',[_c('v-card-title',[_c('span',{staticClass:"pl-1 primary--text"},[_vm._v(_vm._s(_vm.formEditTitle))])]),_c('v-card-text',[_c('v-form',{ref:"form",attrs:{"form":"form","id":"form"},on:{"submit":function($event){$event.preventDefault();return _vm.saveEdit()}},model:{value:(_vm.isFormValid),callback:function ($$v) {_vm.isFormValid=$$v},expression:"isFormValid"}},[_c('v-row',[_c('v-col',{staticClass:"py-0",attrs:{"cols":"4"}},[_c('v-text-field',{directives:[{name:"mask",rawName:"v-mask",value:('#########'),expression:"'#########'"}],staticClass:"theme--light.v-input input",attrs:{"type":"text","outlined":"","dense":"","clearable":"","label":"Agente de cuenta","rules":_vm.rules.required,"autocomplete":"off"},on:{"change":function($event){_vm.codigoAgenteCta == null || _vm.codigoAgenteCta == ''
                  ? _vm.$refs['nombreAgecta'].reset()
                  : null}},model:{value:(_vm.codigoAgenteCta),callback:function ($$v) {_vm.codigoAgenteCta=$$v},expression:"codigoAgenteCta"}})],1),_c('v-col',{staticClass:"py-0",attrs:{"cols":"4"}},[_c('v-text-field',{ref:"nombreAgecta",attrs:{"type":"text","outlined":"","dense":"","clearable":"","disabled":"","label":"Nombre de agente de cta.","rules":_vm.rules.required,"autocomplete":"off"},model:{value:(_vm.nombreAgenteCta),callback:function ($$v) {_vm.nombreAgenteCta=$$v},expression:"nombreAgenteCta"}})],1),_c('v-col',{staticClass:"py-0",attrs:{"cols":"2","sm":"6","md":"2"}},[_c('v-btn',{attrs:{"color":"primary","disabled":_vm.codigoAgenteCta == null ||
                  _vm.codigoAgenteCta == undefined ||
                  _vm.codigoAgenteCta == ''},on:{"click":function($event){return _vm.findAgenteCta()}}},[_vm._v(" Buscar ")])],1),_c('v-col',{staticClass:"py-0 pl-8",attrs:{"cols":"2"}},[_c('v-tooltip',{attrs:{"right":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                  var on = ref.on;
                  var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"absolute":"","fab":"","small":"","color":"primary","disabled":_vm.codigoAgenteCta != null &&
                      _vm.codigoAgenteCta != undefined &&
                      _vm.codigoAgenteCta != ''},on:{"click":function($event){return _vm.toggleModalBusquedaAgecta()}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v(_vm._s(_vm.searchIcon))])],1)]}}])},[_c('span',[_vm._v("Buscar por otro")])])],1)],1),_c('v-row',[_c('v-col',{staticClass:"py-0",attrs:{"cols":"4"}},[_c('v-autocomplete',{attrs:{"items":_vm.tiposComprobantes,"return-object":"","label":"Tipo de comprobante","item-text":"value","item-value":"id","outlined":"","clearable":"","dense":"","rules":_vm.rules.required},on:{"change":function($event){_vm.tipoComprobanteSelected !== null &&
                _vm.tipoComprobanteSelected !== undefined
                  ? _vm.deshabilitarSucursal(_vm.tipoComprobanteSelected)
                  : (_vm.disabledSucursal = false)}},model:{value:(_vm.tipoComprobanteSelected),callback:function ($$v) {_vm.tipoComprobanteSelected=$$v},expression:"tipoComprobanteSelected"}})],1),_c('v-col',{staticClass:"py-0",attrs:{"cols":"4","md":"3"}},[_c('v-text-field',{directives:[{name:"mask",rawName:"v-mask",value:('####'),expression:"'####'"}],attrs:{"label":"Sucursal","outlined":"","dense":"","disabled":_vm.disabledSucursal,"autocomplete":"off","rules":_vm.sucursal === undefined
                  ? _vm.rules.required
                  : _vm.rules.positiveNumberHigher0},model:{value:(_vm.sucursal),callback:function ($$v) {_vm.sucursal=$$v},expression:"sucursal"}})],1),_c('v-col',{staticClass:"py-0",attrs:{"cols":"4"}},[_c('v-autocomplete',{attrs:{"items":_vm.formasPago,"label":"Formas de pago","item-text":"value","item-value":"id","outlined":"","clearable":"","dense":"","return-object":"","rules":_vm.rules.required},on:{"change":function($event){_vm.formaPagoSelected == null || _vm.formaPagoSelected == ''
                  ? _vm.$refs['entFinSelected'].reset()
                  : null}},model:{value:(_vm.formaPagoSelected),callback:function ($$v) {_vm.formaPagoSelected=$$v},expression:"formaPagoSelected"}})],1)],1),_c('v-row',[_c('v-col',{staticClass:"py-0",attrs:{"cols":"6"}},[_c('v-autocomplete',{attrs:{"items":_vm.conceptos,"label":"Concepto","item-text":"value","item-value":"id","outlined":"","clearable":"","dense":"","rules":_vm.rules.required},model:{value:(_vm.conceptoSelected),callback:function ($$v) {_vm.conceptoSelected=$$v},expression:"conceptoSelected"}})],1),_c('v-col',{staticClass:"py-0",attrs:{"cols":"3"}},[_c('v-text-field',{directives:[{name:"mask",rawName:"v-mask",value:('######'),expression:"'######'"}],ref:"periodoDesde",attrs:{"label":"Período desde","outlined":"","dense":"","autocomplete":"not","rules":_vm.rules.required.concat(
                  _vm.rules.periodoYyyyMm,
                  _vm.rules.validDateRange(_vm.periodoDesde, _vm.periodoHasta)
                ),"hint":"Formato AAAAMM","persistent-hint":""},model:{value:(_vm.periodoDesde),callback:function ($$v) {_vm.periodoDesde=$$v},expression:"periodoDesde"}})],1),_c('v-col',{staticClass:"py-0",attrs:{"cols":"3"}},[_c('v-text-field',{directives:[{name:"mask",rawName:"v-mask",value:('######'),expression:"'######'"}],ref:"periodoHasta",attrs:{"label":"Período hasta","outlined":"","dense":"","autocomplete":"not","rules":_vm.rules.required.concat(
                  _vm.rules.periodoYyyyMm,
                  _vm.rules.validDateRange(_vm.periodoDesde, _vm.periodoHasta)
                ),"hint":"Formato AAAAMM","persistent-hint":""},model:{value:(_vm.periodoHasta),callback:function ($$v) {_vm.periodoHasta=$$v},expression:"periodoHasta"}})],1)],1),_c('v-row',[_c('v-col',{staticClass:"py-0",attrs:{"cols":"4"}},[_c('v-autocomplete',{ref:"entFinSelected",attrs:{"items":_vm.entFinancieras,"disabled":_vm.formaPagoSelected != null ? !_vm.formaPagoSelected.bool : true,"label":"Entidad financiera","item-text":"value","item-value":"id","outlined":"","clearable":"","dense":""},model:{value:(_vm.entFinSelected),callback:function ($$v) {_vm.entFinSelected=$$v},expression:"entFinSelected"}})],1),_c('v-col',{staticClass:"py-0",attrs:{"cols":"4"}},[_c('currency-input',{staticClass:"theme--light.v-input input",attrs:{"label":"Importe","dense":"false","outlined":"false","options":_vm.currencyOptions,"rules":_vm.rules.required},model:{value:(_vm.importe),callback:function ($$v) {_vm.importe=$$v},expression:"importe"}})],1),_c('v-col',{staticClass:"py-0",attrs:{"cols":"3"}},[_c('v-switch',{staticClass:"mt-1",attrs:{"id":"tieneIva","label":"Con IVA incluido"},model:{value:(_vm.tieneIva),callback:function ($$v) {_vm.tieneIva=$$v},expression:"tieneIva"}})],1)],1)],1)],1),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"outlined":""},on:{"click":_vm.closeModal}},[_vm._v(" Cancelar ")]),_c('v-btn',{attrs:{"type":"submit","disabled":!_vm.isFormValid,"form":"form","color":"primary"}},[_vm._v(" Guardar ")])],1),(_vm.modalBusquedaAgecta)?_c('v-dialog',{attrs:{"max-width":"65%","persistent":""},on:{"keydown":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"esc",27,$event.key,["Esc","Escape"])){ return null; }return _vm.toggleModalBusquedaAgecta($event)}},model:{value:(_vm.modalBusquedaAgecta),callback:function ($$v) {_vm.modalBusquedaAgecta=$$v},expression:"modalBusquedaAgecta"}},[_c('BuscarAgenteCuenta',{on:{"toggleModalBusquedaAgecta":_vm.toggleModalBusquedaAgecta,"findAgenteCta":_vm.findAgenteCta}})],1):_vm._e()],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }